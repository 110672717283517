import { ConstructionOutlined } from "@mui/icons-material";

export const postType = [
  {
    name: "all",
    title: "All",
  },
  {
    name: "general",
    title: "General",
  },
  {
    name: "newProductAnnouncement",
    title: "NewProductAnnouncement",
  },
  {
    name: "upcomingProductTraining",
    title: "UpcomingProductTraining",
  },
  {
    name: "upcomingEventAnnouncement",
    title: "UpcomingEventAnnouncement",
  },
  {
    name: "eolAnnouncement",
    title: "EolAnnouncement",
  },
  {
    name: "upcomingProjectAnnouncement",
    title: "UpcomingProjectAnnouncement",
  },
  {
    name: "rfpInvite",
    title: "RfpInvite",
  },
  {
    name: "rfqInviteSiteWalkThroughInvite",
    title: "RfqInviteSiteWalkThroughInvite",
  },
  {
    name: "rfqInvite",
    title: "RfqInvite",
  },
  {
    name: "siteWalkThroughInvite",
    title: "SiteWalkThroughInvite",
  },
  {
    name: "other",
    title: "Other",
  },
];

export const PostVisibility = {
  public: "public",
  OnlyMyClients: "onlyMyClients", //connected all client
  OnlySelectedClients: "onlySelectedClients", //organization select list
  OnlyMyVendors: "onlyMyVendors", //connected all vendor
  OnlySelectedVendors: "onlySelectedVendors", //organization select list
  OnlyConsumers: "onlyConsumers", //all consumer
  OnlyProducers: "onlyProducers", //all producer
};

 // export const BASE_URL = "http://localhost:3000/api/";
// export const BASE_URL = "https://vtc-development.vercel.app/api/";
// export const BASE_URL = "https://api.venuetechconnect.com/api/";
export const BASE_URL = process.env.REACT_APP_BASE_URL

export const organizationPlan = {
  VTC_Essential: `VTC_Essential`,
  VTC_Plus: `VTC_Plus`,
};

export const organizationPlanIds = {
  VTC_Essential_Plan_Id: `f47ac10b-58cc-4372-a567-0e02b2c30425`,
  VTC_Plus_Plan_Id: `f47ac10b-58cc-4372-a567-0e02b2c30426`,
};

export const organizationActiveStatuses = {
  default: "default", // Not-Specified (active:true)
  ORGANIZATION_DEACTIVATED_BY_ADMIN: "ORGANIZATION_DEACTIVATED_BY_ADMIN", // (active:false)
};

export const userActiveStatuses = {
  default: "default", // Not-Specified (active:true)
  ORGANIZATION_DEACTIVATED_BY_ADMIN: "ORGANIZATION_DEACTIVATED_BY_ADMIN", // (active:false)
};

export const rssFeedCreatedBy = {
  ORGANIZATION_CREATED: "ORGANIZATION_CREATED",
  ADMIN_CREATED: "ADMIN_CREATED", // Refer to vtc admin
};

export const rosterProductTypes = {
  IMSPT1: "IMSPT1",
  IMSPT2: "IMSPT2",
  IMSPT3: "IMSPT3",
  IMSPT4: "IMSPT4",
  IMSPT5: "IMSPT5",
  IMSPT6: "IMSPT6",
};

export const rosterProductImportDefaultExcelColumn = {
  Product_Type: "Product Type",
  Product_Category: "Product Category",
  Location_Id: "Location Id",
  Manufacturer: "Manufacturer",
  Product_Name: "Product Name",
  Status: "Status",
  Model_Number: "Model Number",
  Serial_Number: "Serial Number",
  Manufacturer_Product_Id: "Manufacturer Product Id",
  Vendor_Product_Id: "Vendor Product Id",
  Purchase_Date: "Purchase Date",
  Warranty_Start_Date: "Warranty Start Date",
  Warranty_End_Date: "Warranty End Date",
  Purchase_Price_Type: "Purchase Price Type",
  Purchase_Price: "Purchase Price",
  AssetTag_Number: "AssetTag Number",
  Consultant_Organization: "Consultant Organization",
  Integrator_Organization: "Integrator Organization",
  Vendor_Organization: "Vendor Organization",
  Expected_Replacement_Date: "Expected Replacement Date",
  End_of_Life_Date: "End of Life Date",
  Version: "Version",
  // Quantity: "Quantity",
  // License_Type: "License Type",
  // Renewal_Type: "Renewal Type",
  // License_Auto_Renew: "License Auto-Renew",
  // Pixel_Pitch:"Pixel Pitch",
  // Technology: "Technology",
  // Display_Serviced_By: "Display Serviced By",
};

export const rosterProductImportDefaultExcelSelectableColumn = {
  Product_Type: "Product Type",
  Product_Category: "Product Category",
  Manufacturer: "Manufacturer",
  Status: "Status",
  Purchase_Price_Type: "Purchase Price Type",
  Consultant_Organization: "Consultant Organization",
  Integrator_Organization: "Integrator Organization",
  Vendor_Organization: "Vendor Organization",
  // License_Type: "License Type",
  // Renewal_Type: "Renewal Type",
  // License_Auto_Renew: "License Auto-Renew",
  // Technology: "Technology",
  // Display_Serviced_By: "Display Serviced By",
};

export const rosterProductImportDefaultExcelDateColumn = {
  Purchase_Date: "Purchase Date",
  Warranty_Start_Date: "Warranty Start Date",
  Warranty_End_Date: "Warranty End Date",
  Expected_Replacement_Date: "Expected Replacement Date",
  End_of_Life_Date: "End of Life Date",
};

export const rosterProductImportExcelSheetProductType = {
  Single_Product: "Single Product",
  Part: "Part",
  Kit: "Kit",
  Software_License: "Software License",
  Group: "Group",
  System: "System",
};

export const rosterProductImportExcelSheetStatus = {
  New: "New",
  In_Use: "In Use",
  Out_for_Service: "Out for Service",
  Storage_Spare: "Storage/Spare",
  Removed: "Removed",
  List_for_Sale: "List for Sale",
  List_for_Donation_or_Recycle: "List for Donation or Recycle",
};

export const rosterProductImportExcelSheetPurchasePriceType = {
  Actual_Price: "Actual Price",
  Estimated_Price: "Estimated Price",
};

// export const rosterProductImportExcelSheetLicenseType = {
//   Perpetual: "Perpetual",
//   Renew: "Renew",
// };

// export const rosterProductImportExcelSheetRenewalType = {
//   Monthly: "Monthly",
//   Annual: "Annual",
// };

// export const rosterProductImportExcelSheetLicenseAutoRenew = {
//   Yes: "Yes",
//   No: "No",
// };

// export const rosterProductImportExcelSheetTechnology = {
//   Yes: "DIP",
//   SMD: "SMD",
//   COB: "COB",
//   Other: "Other",
// };

// export const rosterProductImportExcelSheetDisplayServicedBy = {
//   Service_by_us: "Service by us",
//   Service_by_vendor: "Service by vendor",
// };

export const rosterProductImportExcelSheetMandatoryFieldsForAllProductType = {
  Product_Type: "Product Type",
  Product_Category: "Product Category",
  Location_Id: "Location Id",
  Manufacturer: "Manufacturer",
  Product_Name: "Product Name",
  Status: "Status",
};

// export const rosterProductImportExcelSheetMandatoryFieldsByProductType = {
//   Part: {
//     Quantity: "Quantity",
//   },
//   Kit: {},
//   Software_License: {
//     Quantity: "Quantity",
//     License_Type: "License Type",
//     Renewal_Type: "Renewal Type",
//     License_Auto_Renew: "License Auto-Renew",
//   },
//   Group: {},
//   System: {
//     Pixel_Pitch:"Pixel Pitch",
//     Technology: "Technology",
//     Display_Serviced_By: "Display Serviced By",
//   },
// };
