import {
    Alert,
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
  } from "@mui/material";
  import {
    createUpdateRSSFeedByAdmin,
    DeleteErrorLogs,
    deleteRSSFeedByAdmin,
    fetchPostTypeListForNewswire,
    getErrorList,
    getNewswirePostsWithFilters,
    getProductList,
    getRSSFeedWithFilters,
    getUnverifiedProducer,
  } from "api/api";
  import moment from "moment";
  import React from "react";
  import { useState } from "react";
  import { useEffect } from "react";
  import DataTable from "react-data-table-component";
  import AddIcon from "@mui/icons-material/Add";
  
  import Button from "@mui/material/Button";
  import { CSVLink } from "react-csv";
  import { Link } from "react-router-dom";
  import { toast } from "react-toastify";
  import Box from "@mui/material/Box";
  import { BASE_URL } from "utils/constant";
  import {
    Chip,
    Divider,
    Grid,
    Modal,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import Loader from "ui-component/Loader";
  import { useLocation, useNavigate, useParams } from "react-router";
  import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
  import {
    fetchOrganizationNewswirePosts,
    fetchOrganizationPlans,
  } from "api/api";
  import Avatar from "@mui/material/Avatar";
  import Tooltip from "@mui/material/Tooltip";
  import { AlertDialog, handleDateTime } from "utils/common";
  import { useSearchParams } from "react-router-dom";
  import axios from "axios";
  import { Settings } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
  
  export default function QueueManagementIndex() {
    const [columns, setColumns] = useState([]);
    const [loader, setLoading] = useState(false)
    const [data, setData] = useState([]);
  
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
  
    const [open, setOpen] = useState(false);
    const [openDeleteAll, setOpenDeleteAll] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedJobDetails,setSelectedJobDetails] = useState({})
  
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
  
    const theme = useTheme();
  
    // Media query to check screen size, e.g., smaller screens (600px and below)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  
    // Extract filters from query params
    const queueFilter = searchParams.get("queue") || "NOTIFICATION_QUEUE";
    const queueStatusFilter = searchParams.get("queueStatus") || "completed";
    const orgPlanFilter = searchParams.get("orgPlan") || "All";
  
    const [searchText,setSearchText] = useState('')
  
  
    // State for filter values and the data from the API
    const [filterValues, setFilterValues] = useState({
      queue:queueFilter,
      queueStatus:queueStatusFilter,
      orgPlan:orgPlanFilter
    });
  
    // Function to update query parameters and trigger API call
    const handleFilterChange = (key, value) => {
      setFilterValues((prev) => ({ ...prev, [key]: value }));
      searchParams.set(key, value);
      navigate({
        pathname: "/dashboard/queue",
        search: `?${searchParams.toString()}`,
      });
    };
    const [alertDialogopen, setAlertDialogopen] = React.useState(false);
    const [alertDialogData, setAlertDialogData] = React.useState({});
  
    const location = useLocation();
  
    const handleCloseAlertDialog = () => {
      setAlertDialogopen(false);
      setAlertDialogData({});
    };
  
    const handleActionsOnAlertDialog = async (data, action) => {
      switch (action) {
        case "Disagree":
          handleCloseAlertDialog();
          break;
        case "Agree":
          try {
            const response = await deleteRSSFeedByAdmin(data?.id);
            if (response.data.status === "success") {
              toast.success("Success", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              handleCloseAlertDialog();
            }
          } catch (error) {
            const errorMessage = error?.response?.data?.message;
            alert(errorMessage);
          }
  
          break;
  
        default:
          alert("Action not defined!");
      }
    };
  

  

    const handlePerRowsChange = async (newPerPage, page) => {
      setPerPage(newPerPage);
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString("en-US", {
          hour12: true,
        });
      };
  
    // Fetch data from the server when filter values change
    const fetchOrganizationFilteredData = async () => {
      setLoading(true);
  
      try {
        const res = await axios.get("/queue/jobs", {
          params: {
            queueName:filterValues.queue,
            status:filterValues.queueStatus,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
          baseURL: BASE_URL,
        });
  
        setData(res.data.jobs);
        setTotalRows(res.data.totalJobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch single job details
    const fetchSingleJobDetails = async () => {
      setLoading(true);
  
      try {
        const res = await axios.get("/queue/jobDetails", {
          params: {
            queueName:filterValues.queue,
            jobId:selectedRow.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
          baseURL: BASE_URL,
        });
  
        setSelectedJobDetails(res.data);
        
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };
  

  
    useEffect(() => {
      // Trigger any function or state update when query params change
       console.log('Query params changed:', location.search);
       // Create a URLSearchParams object with the search string
       const searchParams = new URLSearchParams(location.search);
  
       // Extract values from the query parameters
       const queueParam = searchParams.get('queue') || 'NOTIFICATION_QUEUE';
       const queueStatusParam = searchParams.get('queueStatus') || 'completed';
      
      setFilterValues((pre)=>{
        return {
            queue:queueParam,
            queueStatus:queueStatusParam,
        }
      })
  
      // Any logic that you want to run on query param changes
      // For example, fetch data based on new query params
    }, []); // This dependency will re-run the effect when query params change
  
    const handleRowClick = (row) => {
        setSelectedRow(row);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
      };

    const getStatusBadge = (status) => {
        const statusStyles = {
          Active: { background: "#E6F4FF", color: "#1890FF" },
          Completed: { background: "#E6F8E0", color: "#389E0D" },
          Failed: { background: "#FEECEC", color: "#FF4D4F" },
          Waiting: { background: "#FFF7E6", color: "#FAAD14" },
          Delayed: { background: "#F6E6FF", color: "#722ED1" },
          Paused: { background: "#F0F0F0", color: "#8C8C8C" },
        };
      
        const style = {
          backgroundColor: statusStyles[status]?.background || "#D9D9D9",
          color: statusStyles[status]?.color || "#595959",
          padding: "5px 10px",
          borderRadius: "15px",
          fontWeight: "bold",
          display: "inline-block",
        };
      
        return <span style={style}>{status}</span>;
      };


    useEffect(() => {
      setColumns([
        {
            name: "Index",
            selector: (row) => row.index,
            sortable: false,
            maxWidth: "200px",
        },
        {
            name: "Job Id",
            selector: (row) => row.id,
            sortable: false,
            maxWidth: "600px",
        },
        {
          name: "Job Name",
          selector: (row) => row.name,
          sortable: false,
          maxWidth: "600px",
        },
        {
          name: "Status",
          selector: (row) => <>{getStatusBadge(row.status)}</>,
          sortable: false,
          maxWidth: "600px",
        },
        {
          name: "Created At",
          selector: (row) => handleDateTime(row.createdAt),
          sortable: false,
        },
        // {
        //   name: "Completed Time",
        //   selector: (row) => row.subRole,
        //   sortable: false,
        // },
        // {
        //   name: "Actions",
        //   selector: (row) => row.plan,
        //   sortable: false,
        // },
      
       
      ]);
    }, []);
    
  
    const handlePageChange = (page) => {
      setPage(page);
    };
  
    const handleTableSorting = (columnName,sortDirection)=>{
      
    }

      // Open the confirmation dialog
      const handleDeleteConfirmationOpen = () => setDeleteConfirmationOpen(true);

      // Close the dialog
      const handleDeleteConfirmationClose = () => setDeleteConfirmationOpen(false);

      // Handle job deletion
      const handleDelete = async (jobId) => {
        setLoading(true);
        try {
          const res = await axios.delete("/queue/job", {
            params: {
              queueName:filterValues.queue,
              jobId:jobId,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
            },
            baseURL: BASE_URL,
          });
    
          
          alert('deleted successfully!')
          window.location.reload(); // Refreshes the entire page
        } catch (error) {
          console.error("Error while deleting the job:", error);
        } finally {
          setLoading(false);
        }
      };

    async function handleDeleteAllJobs() {
      setLoading(true);
      try {
        const res = await axios.delete("/queue/deleteAllJobs", {
          params: {
            queueName:filterValues.queue,
            status:filterValues.queueStatus,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
          baseURL: BASE_URL,
        });
  
        
        window.location.reload(); // Refreshes the entire page
      } catch (error) {
        console.error("Error while deleting the job:", error);
      } finally {
        setLoading(false);
      }
    }
  
    // Fetch data when component mounts or query parameters change
    useEffect(() => {
      fetchOrganizationFilteredData();
    }, [filterValues.queue,filterValues.queueStatus]);

      // Fetch row details when modal opens
  useEffect(() => {
    if (open && selectedRow) {
        fetchSingleJobDetails()
    }
  }, [open, selectedRow]);
  
    return (
      <>
       
        <Box sx={{ display: "flex", gap: 2, mb: 1, background: "", p: 1 }}>
  

          {/* Filter 1: Organization Role */}
          <FormControl variant="outlined">
            <InputLabel>Select Queue</InputLabel>
            <Select
              label="Select Queue"
              size="small"
              sx={{
                width:'200px',
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "0px", // Make the border square
                },
              }}
              value={filterValues.queue}
              onChange={(e) => handleFilterChange("queue", e.target.value)}
            >
              <MenuItem value="NOTIFICATION_QUEUE">NOTIFICATION_QUEUE</MenuItem>
              {/* <MenuItem value="Producer">Producer</MenuItem> */}
            </Select>
          </FormControl>

                    {/* Filter 2: Organization Sub Role */}
                    <FormControl variant="outlined">
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              size="small"
              sx={{
                width:'200px',
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "0px", // Make the border square
                },
              }}
              value={filterValues.queueStatus}
              onChange={(e) => handleFilterChange("queueStatus", e.target.value)}
            >
                {/* 'waiting', 'active', 'completed', 'failed', 'delayed' */}
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="failed">Failed</MenuItem>
              <MenuItem value="delayed">Delayed</MenuItem>
              <MenuItem value="waiting">Waiting</MenuItem>
             
            </Select>
          </FormControl>

          <Box>
          <Button onClick={()=>{setOpenDeleteAll(true)}} variant="outlined" color="error" >Delete All - {filterValues.queueStatus}</Button>
          <Tooltip title={`500 jobs will be deleted`} arrow>
            <IconButton color="primary">
              <InfoIcon />
            </IconButton>
          </Tooltip>
          </Box>
          
        </Box>
  
        <Box mt={1} overflow={"hidden"}>
          {loader ? <Loader/>:
          (
            <DataTable
            customStyles={{
              subHeader: {
                style: {},
              },
              header: {
                style: {
                  borderTopLeftRadius: "10px", // Top left corner
                  borderTopRightRadius: "10px", // Top right corner
                },
              },
            }}
            // title={<p>{"Organizations"}</p>}
  
            columns={columns}
            data={data}
            pagination
            onRowClicked={handleRowClick}
            // subHeader={true}
            // subHeaderComponent={subHeaderComponentMemo2}
            persistTableHead
            fixedHeader
            // paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pointerOnHover
            onSort={(column, sortDirection) => handleTableSorting(column.name, sortDirection)} // Listen to sort event
            
          />
          )}
        </Box>
  
        <AlertDialog
          title={"Feed Delete Confirmation"}
          content={
            "Do you really want to delete this RSS feed? Please confirm your action."
          }
          onClose={handleCloseAlertDialog}
          open={alertDialogopen}
          actions={[
            {
              label: "No",
              onClick: handleActionsOnAlertDialog,
              action: "Disagree",
            },
            {
              label: "Yes",
              onClick: handleActionsOnAlertDialog,
              autoFocus: true,
              action: "Agree",
            },
          ]}
          data={alertDialogData}
        />

        {/* Modal */}
        {/* <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height:400,
            bgcolor: "white",
            boxShadow: 14,
            p: 4,
            borderRadius: 0,
          }}
        >
          <h2>Job Details</h2>
          {selectedJobDetails && (
            <>
              <p><strong>ID:</strong> {selectedJobDetails.id}</p>
              <p><strong>Name:</strong> {selectedJobDetails.name}</p>
              <p><strong>
              Processed On:</strong> {selectedJobDetails.processedOn}</p>
            </>
          )}
        </Box>
      </Modal> */}

<Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "white",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          {/* Header */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" fontWeight="bold">
              Job Details
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                sx={{ bgcolor: "#f0f0f0", px: 1, py: 0.5, borderRadius: 1, mr: 1 }}
              >
                {selectedJobDetails.id}
              </Typography>
              <Tooltip title="Copy Job ID">
                <IconButton size="small" onClick={() => navigator.clipboard.writeText(selectedJobDetails.id)}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <IconButton size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Status Badge */}
          <Box display="flex" gap={2} alignItems="center" mb={2}>
            
            <Typography
              variant="body2"
              sx={{ bgcolor: "#e6f4ea", px: 1, py: 0.5, borderRadius: 1, ml: 1 }}
            >
              {selectedJobDetails.status?.status}
            </Typography>
            {
            selectedJobDetails.status?.status && <CheckCircleIcon color="success" />
           }
          </Box>

          {/* Job Name & Data */}
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Job Name
              </Typography>
              <Paper sx={{ p: 1, borderRadius: 1 }} variant="outlined">
                <Typography>{selectedJobDetails.name}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Job data
              </Typography>
              <div style={{display:'flex',alignItems:'center'}}>
              <Paper sx={{ p: 1, borderRadius: 1,flex:9 }} variant="outlined">
                <Typography>{JSON.stringify(selectedJobDetails.data)}</Typography>
              </Paper>
              <Tooltip title="Copy Job Data">
                <IconButton size="small" onClick={() => navigator.clipboard.writeText(JSON.stringify(selectedJobDetails.data))}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              </div>
            </Grid>
            
          </Grid>

          {/* Status Information */}
          <Typography variant="body2" fontWeight="bold" mb={1}>
            Status Information
          </Typography>
          <Paper sx={{ p: 2, borderRadius: 2, bgcolor: "#f8f9fa" }} variant="outlined">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Progress <Tooltip title="Job progress"><InfoIcon fontSize="small" /></Tooltip>
                </Typography>
                <Typography fontWeight="bold">{selectedJobDetails.progress}%</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Attempts Made <Tooltip title="Number of attempts"><InfoIcon fontSize="small" /></Tooltip>
                </Typography>
                <Typography fontWeight="bold">{selectedJobDetails.attemptsMade}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Failed Reason</Typography>
                <Typography fontWeight="bold">{selectedJobDetails.failedReason ?? "None"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Return Value</Typography>
                <Typography fontWeight="bold">{selectedJobDetails.returnValue ?? "None"}</Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* Timing Information */}
          <Typography variant="body2" fontWeight="bold" mt={2} mb={1}>
            Timing Information
          </Typography>
          <Paper sx={{ p: 2, borderRadius: 2, bgcolor: "#f8f9fa" }} variant="outlined">
            <Box display="flex" alignItems="center" mb={1}>
              <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">
                <strong>Created At:</strong> {formatDate(selectedJobDetails.status?.createdAt)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">
                <strong>Processed On:</strong> {formatDate(selectedJobDetails.processedOn)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">
                <strong>Finished On:</strong> {formatDate(selectedJobDetails.finishedOn)}
              </Typography>
            </Box>
          </Paper>

          {/* Delete Button */}
          <Button
            variant="contained"
            color="error"
            fullWidth
            sx={{ mt: 2 }}
            onClick={()=>{handleDeleteConfirmationOpen()}}
          >
            Delete
          </Button>
          {/* Confirmation Dialog */}
          <Dialog 
            maxWidth="xs" // Adjust width to a smaller size
            fullWidth // Ensures responsiveness
            // PaperProps={{ sx: { width: "400px" } }} // Fine-tune width if needed
          open={deleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete job?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteConfirmationClose} color="secondary">Cancel</Button>
              <Button onClick={()=>{handleDelete(selectedJobDetails.id)}} color="error" variant="contained">Delete</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>   

        <Dialog 
            maxWidth="xs" // Adjust width to a smaller size
            fullWidth // Ensures responsiveness
            // PaperProps={{ sx: { width: "400px" } }} // Fine-tune width if needed
          open={openDeleteAll} onClose={()=>{setOpenDeleteAll(false)}}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete job?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{setOpenDeleteAll(false)}} color="secondary">Cancel</Button>
              <Button onClick={()=>{handleDeleteAllJobs()}} color="error" variant="contained">Delete</Button>
            </DialogActions>
          </Dialog>

      </>
    );
  }
  