// assets
import {
  IconDashboard,
  IconUserCircle,
  IconFileExport,
  IconCategory2,
  IconMessage2,
  IconCalendarEvent,
  IconAssembly,
  IconAffiliate,
  IconMapPin,
  IconReport,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconUserCircle,
  IconFileExport,
  IconCategory2,
  IconMessage2,
  IconCalendarEvent,
  IconAssembly,
  IconAffiliate,
  IconMapPin,
  IconReport,
};
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  //   title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "sample-page",
      title: "Organization Manage",
      type: "item",
      url: "/dashboard/organization-list",
      icon: icons.IconUserCircle,
      breadcrumbs: false,
    },
    {
      id: "organizations",
      title: "organizations",
      type: "item",
      url: "/dashboard/organizations?organizationRole=All&organizationSubRole=All&orgPlan=All",
      icon: icons.IconUserCircle,
      breadcrumbs: false,
      isNew: true, // Add this property to mark this as a new item
    },
    {
      id: "User Export",
      title: "Organization Import",
      type: "collapse",
      url: "/dashboard/organization-import",
      icon: icons.IconFileExport,
      breadcrumbs: false,
      children: [
        // {
        //   id: "Export",
        //   title: "Import",
        //   type: "item",
        //   url: "/dashboard/organization-import",
        //   target: true,
          
        // },
        {
          id: "connection request",
          title: "Ownership Request",
          type: "item",
          url: "/dashboard/request",
          target: true,
        },
      ],
    },
    {
      id: "Feature Vendor",
      title: "Feature Vendor",
      type: "item",
      url: "/dashboard/feature-vendor-list",
      icon: icons.IconCategory2,
      breadcrumbs: false,
    },
    // {
    //   id: "Organization Management",
    //   title: "Organization Management",
    //   type: "item",
    //   url: "/dashboard/organization-management",
    //   // icon: icons.IconBrandChrome,
    //   breadcrumbs: false,
    // },
    {
      id: "Category Management",
      title: "Category Management",
      type: "item",
      url: "/dashboard/category-management",
      icon: icons.IconCategory2,
      breadcrumbs: false,
    },
    {
      id: "Newswire Post",
      title: "Newswire Post",
      type: "item",
      url: "/dashboard/newswire-post",
      icon: icons.IconCategory2,
      breadcrumbs: false,
      isNew: true, // Add this property to mark this as a new item
    },
    {
      id: "RSS Feed",
      title: "RSS Feed",
      type: "item",
      url: "/dashboard/rss-feed",
      icon: icons.IconCategory2,
      breadcrumbs: false,
      isNew: true, // Add this property to mark this as a new item
    },
    // {
    //   id: "Newswire post Manage",
    //   title: "Newswire post Manage",
    //   type: "item",
    //   url: "newswire/manage/post",
    //   icon: icons.IconCategory2,
    //   breadcrumbs: false,
    // },

    // {
    //   id: "Message Moderation",
    //   title: "Message Moderation",
    //   type: "item",
    //   url: "/dashboard/message-moderation",
    //   icon: icons.IconMessage2,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "User Permissions",
    //   title: "User Permissions",
    //   type: "item",
    //   url: "/dashboard/user-permission",
    //   // icon: icons.IconBrandChrome,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "Connection Moderation",
    //   title: "Connection Moderation",
    //   type: "item",
    //   url: "/dashboard/connection-moderation",
    //   icon: icons.IconAffiliate,
    //   breadcrumbs: false,
    // },
    {
      id: "Event Management",
      title: "Event Management",
      type: "collapse",
      url: "/dashboard/event/management",
      icon: icons.IconCalendarEvent,
      breadcrumbs: false,
      children: [
        {
          id: "Export",
          title: "Event List",
          type: "item",
          url: "/dashboard/event/management",
          target: true,
        },
        {
          id: "Event Create",
          title: "Create Event ",
          type: "item",
          url: "/dashboard/event/create",
          target: true,
        },
        // {
        //   id: "Update Create",
        //   title: "Update Event",
        //   type: "item",
        //   url: "/dashboard/event/update",
        //   target: true,
        // },
        // {
        //   id: "Organization Create",
        //   title: "Organization Create",
        //   type: "item",
        //   url: "/dashboard/organization/create",
        //   target: true,
        // },
      ],
    },
    // {
    //   id: "Client Management",
    //   title: "Client Management",
    //   type: "item",
    //   url: "/dashboard/client/management",
    //   icon: icons.IconCategory2,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "Vendor Management",
    //   title: "Vendor Management",
    //   type: "item",
    //   url: "/dashboard/vendor/management",
    //   icon: icons.IconCategory2,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "Product Management",
    //   title: "Product Management",
    //   type: "item",
    //   url: "/dashboard/product/management",
    //   icon: icons.IconAssembly,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "IMS Location Management",
    //   title: "IMS Location",
    //   type: "item",
    //   url: "/dashboard/ims-flow-management",
    //   icon: icons.IconMapPin,
    //   breadcrumbs: false,
    // },
    // {
    //   id: "IMS Product Management",
    //   title: "IMS Product",
    //   type: "item",
    //   url: "/dashboard/ims-product-management",
    //   icon: icons.IconMapPin,
    //   breadcrumbs: false,
    // },
    {
      id: "Unverified Producer",
      title: "Unverified Producer",
      type: "item",
      url: "/dashboard/unverified-producer",
      icon: icons.IconMapPin,
      breadcrumbs: false,
    },
    {
      id: "Setting",
      title: "Setting",
      type: "item",
      url: "/dashboard/setting",
      icon: icons.IconAssembly,
      breadcrumbs: false,
    },
    {
      id: "Block",
      title: "Block Management",
      type: "item",
      url: "/dashboard/block",
      icon: icons.IconAssembly,
      breadcrumbs: false,
    },
    {
      id: "Queue",
      title: "Queue",
      type: "item",
      url: "/dashboard/queue?queue=NOTIFICATION_QUEUE&queueStatus=completed",
      icon: icons.IconAssembly,
      breadcrumbs: false,
      isNew: true, // Add this property to mark this as a new item
    },
    {
      id: "faq",
      title: "FAQ",
      type: "item",
      url: "/dashboard/faq",
      icon: icons.IconMessage2,
      breadcrumbs: false,
    },
    {
      id: "errorLogs",
      title: "Error Logs",
      type: "item",
      url: "/dashboard/error/logs",
      icon: icons.IconReport,
      breadcrumbs: false,
    },
    {
      id: "Logs Management",
      title: "Delete Logs",
      type: "collapse",
      url: "/dashboard/logs/user",
      icon: icons.IconReport,
      breadcrumbs: false,
      children: [
        {
          id: "User Delete Logs",
          title: "User Logs",
          type: "item",
          url: "/dashboard/logs/user",
          target: true,
        },
        {
          id: "Organization Delete Logs",
          title: "Organization Logs",
          type: "item",
          url: "/dashboard/logs/organization",
          target: true,
        },
      ],
    },
  ],
};

export default dashboard;
